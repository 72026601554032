<template>
  <section class="A4 sheet">
    <div>
      <!-- Start: Logo & Title -->
      <div class="d-flex align-center justify-space-between">
        <div>
          <div class="sheet-logo--container">
            <img
              alt="Logo"
              src="../assets/images/logo-thinkpro.svg"
              width="128.6px"
            />
          </div>
          <div class="mt-2" style="max-width: 250px">
            <div class="sheet-font--bold">Chi nhánh</div>
            <div>{{ order && order.branch ? order.branch.address : null }}</div>
          </div>
        </div>
        <div class="d-flex">
          <div>
            <div class="sheet-title sheet-font--bold">PHIẾU THU ĐẶT CỌC</div>
            <div class="mt-2">
              <div class="d-flex align-center justify-space-between">
                <div>Đơn hàng</div>
                <div class="sheet-font--bold">{{ order && order.code }}</div>
              </div>
              <div class="d-flex align-center justify-space-between">
                <div>Phiếu thu</div>
                <div class="sheet-font--bold">
                  {{
                    order && order.payment_info
                      ? order.payment_info[0].code
                      : null
                  }}
                </div>
              </div>
              <!--              <div>-->
              <!--                (<strong>CLICK</strong> hoặc <strong>SCAN</strong> mã QR để biết-->
              <!--                thêm chi tiết)-->
              <!--              </div>-->
            </div>
          </div>
          <!--          <div class="ml-5" style="margin-top: 10px">-->
          <!--            <img-->
          <!--              width="90.70866px"-->
          <!--              src="../assets/images/qr-code.svg"-->
          <!--              alt="QR Code"-->
          <!--            />-->
          <!--          </div>-->
        </div>
      </div>
      <!-- End: Logo & Title -->
      <div class="sheet-divider my-5"></div>
      <!-- Start: Main -->
      <div
        v-if="order && order.payment_info && order.payment_info[0]"
        class="d-flex"
      >
        <div class="col pa-0">
          <div class="sheet-font--bold">Nhân viên thu</div>
          <div>
            {{
              order.payment_info[0].receiver &&
                order.payment_info[0].receiver.name
            }}
          </div>
        </div>
        <div class="col sheet-text--center pa-0">
          <div class="sheet-font--bold">Số tiền</div>
          <div>
            {{ order.payment_info[0].payment_amount | formatCurrency("VND") }}
          </div>
        </div>
        <div class="col d-flex flex-column align-end sheet-text--right pa-0">
          <div class="sheet-font--bold">Ngày dự kiến giao hàng</div>
          <div class="dot-line--est"></div>
        </div>
      </div>
      <!-- End: Main -->
      <div class="sheet-divider my-5"></div>
      <!-- Start: Responsibility -->
      <div class="sheet-responsibility">
        <div class="sheet-font--bold">TRÁCH NHIỆM VÀ CAM KẾT</div>

        <ul>
          <li class="text-content">
            <div class="text-back">
              Phiếu đặt hàng đảm bảo Quý khách sẽ được mua hàng khi hàng có sẵn
              trên hệ thống của ThinkPro theo đúng thứ tự.
            </div>
          </li>
          <li class="text-content">
            <div class="text-back">
              ThinkPro có trách nhiệm đảm bảo có hàng cho quý khách.
            </div>
          </li>
          <li class="text-content">
            <div class="text-back">
              Trong trường hợp không có hàng (trừ trường hợp hàng về nhưng do
              điều kiện khách quan hoặc quy cách sai lệch), ThinkPro sẽ hoàn lại
              cho quý khách 102% số tiền đã đặt cọc ban đầu.
            </div>
          </li>
        </ul>

        <div class="d-flex cashback-option">
          <div class="col cashback-option--item px-0 pt-8 pb-5">
            <div class="d-flex align-center justify-center sheet-font--bold">
              <div class="cashback-option--item-dot mr-2"></div>
              <div>HOÀN TIỀN</div>
              <div class="line"></div>
              <div>(%)</div>
            </div>
          </div>
          <div class="col cashback-option--item px-0 pt-8 pb-5">
            <div class="d-flex align-center justify-center sheet-font--bold">
              <div class="cashback-option--item-dot mr-2"></div>
              <div>KHÔNG HOÀN TIỀN</div>
            </div>
          </div>
        </div>
      </div>
      <!-- End: Responsibility -->
      <div class="sheet-divider mt-5 mb-8"></div>
    </div>
    <!-- Start: Signature -->
    <div class="d-flex sheet-signatures mb-8">
      <div class="col sheet-text--center pa-0">
        <div class="sheet-font--bold">XÁC NHẬN TỪ THINKPRO</div>
        <div>(Chữ ký, đóng dấu)</div>
      </div>
      <div class="col sheet-text--center pa-0">
        <div class="sheet-font--bold">XÁC NHẬN TỪ KHÁCH HÀNG</div>
        <div>(Chữ ký, Họ & Tên)</div>
      </div>
    </div>
    <!-- End: Signature -->
    <div>
      <div class="sheet-divider my-3"></div>
      <!-- Start: Contacts -->
      <div class="d-flex sheet-contact">
        <div class="col sheet-font--bold pa-0">LIÊN HỆ VỚI CHÚNG TÔI</div>
        <div class="col pa-0"><strong>Hotline:</strong> 1900 633 579</div>
        <div class="col sheet-text--center pa-0">
          <strong>Website:</strong> www.thinkpro.vn
        </div>
        <div class="col sheet-text--right pa-0">
          <strong>Fanpage:</strong> fb.com/thinkpro.vn
        </div>
      </div>
      <!-- End: Contacts -->
      <div class="sheet-divider my-3"></div>
      <!-- Start: Stores -->
      <div class="d-flex sheet-stores">
        <div class="col-3 sheet-font--bold pa-0">HỆ THỐNG CỬA HÀNG</div>
        <div class="col-4 pa-0">
          <div class="d-flex">
            <div class="sheet-font--bold">Hà Nội:</div>
            <ul>
              <li><span class="text-back">53 Thái Hà, Q. Đống Đa</span></li>
            </ul>
          </div>
        </div>
        <div class="col-5 pa-0">
          <div class="d-flex justify-end">
            <div class="sheet-font--bold">TP.HCM:</div>
            <ul>
              <li>
                <div class="text-back">
                  5-7 Nguyễn Huy Tưởng, P. 6, Q. Bình Thạnh
                </div>
              </li>
              <li>
                <div class="text-back">95 Trần Thiện Chánh, P. 12, Q. 10</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- End: Stores -->
    </div>
  </section>
</template>

<script>
export default {
  computed: {
    order() {
      return this.$store.getters["ORDER/order"];
    }
  },
  async mounted() {
    const route = this.$route;

    await this.$store.dispatch("ORDER/getOrderById", route.params.orderId);
    this.printPage();
  },
  methods: {
    printPage() {
      this.$nextTick(() => {
        document.title =
          this.order.code + "-" + this.order.payment_info[0].code;
        window.print();
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
